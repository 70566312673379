<template>
    <div>
        <CRow>
            <CCol lg="7">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="dice"/>  New Matrix
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="12">
                                <CInput 
                                    :lazy="false"
                                    :value.sync="$v.tsm.name.$model"
                                    :isValid="checkIfValid($v.tsm, 'name')"
                                    label="Name" 
                                    type="text" 
                                    autocomplete="off" 
                                    v-nospecialcharacter
                                    invalidFeedback="Must be three(3) characters."
                                    v-tocapitalize
                                    v-model="tsm.name"
                                >
                                </CInput>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                                <CTextarea
                                    :lazy="false"
                                    :value.sync="$v.tsm.description.$model"
                                    :isValid="checkIfValid($v.tsm, 'description')"
                                    class="grp-text-area"
                                    label="Description"
                                    placeholder="Content..."
                                    autocomplete="off"  
                                    v-tocapitalize
                                    v-model="tsm.description"
                                    invalidFeedback="Must be more than five(5) characters."
                                />
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader><font-awesome-icon icon="fa-barcode"/> Matrix Purposes
                        <font-awesome-icon style="color: #4caf50; " icon="info-circle" size="lg"/>
                    </CCardHeader>
                    <CCardBody>
                        <CRow>
                            <CCol lg="1"/>
                            <CCol lg="7">
                                Purpose
                            </CCol>
                            <CCol lg="2">
                                Sequence <a-popover title="WARNING!" placement="left">
                                    <template slot="content">
                                        Duplicate sequence detected. If you wish to continue, the sequence <br>
                                        in the list will be automatically adjusted.
                                    </template>
                                    <font-awesome-icon 
                                        v-if="duplicate_sequence"
                                        style="color: orange; margin-right:10px" 
                                        icon="info-circle" 
                                        size="lg"/>
                                </a-popover>
                            </CCol>
                            <CCol lg="1"/>
                            <CCol lg="1"/>
                        </CRow>
                        <CRow>
                            <CCol lg="1"/>
                            <CCol lg="7">
                                <CInput 
                                    type="text" 
                                    autocomplete="off" 
                                    v-nospecialcharacter
                                    v-tocapitalize
                                    v-model="add.purpose"
                                    >
                                </CInput>
                            </CCol>
                            <CCol lg="2">
                                <CInput 
                                    type="text" 
                                    maxlength="2"
                                    onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')"
                                    autocomplete="off" 
                                    v-model="add.sequence"
                                    >
                                </CInput>
                            </CCol>
                            <CCol lg="1">
                                <CButton
                                    :disabled="!add.purpose || !add.sequence"
                                    color="primary"
                                    @click="addPurpose()"
                                >
                                    <font-awesome-icon icon="plus"/>
                                </CButton>
                            </CCol>
                            <CCol lg="1"/>
                        </CRow>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="purpose_list"
                                    :fields="purpose_list_field"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    border
                                    items-per-page-select
                                    sorter
                                    pagination
                                >
                                    <template #purpose="{item}">
                                        <td>
                                            <CInput 
                                                type="text" 
                                                autocomplete="off" 
                                                v-model="item.purpose"
                                                >
                                            </CInput>
                                        </td>
                                    </template>
                                    <template #sequence="{item}">
                                        <td>
                                            <CInput 
                                                type="text" 
                                                maxlength="2"
                                                onkeyup="if (/\D/g.test(this.value)) this.value = this.value.replace(/\D/g,'')"
                                                autocomplete="off" 
                                                v-model="item.sequence"
                                                >
                                            </CInput>
                                        </td>
                                    </template>
                                    <template #action="{item, index}">
                                        <td>
                                            <CButton color="danger" shape="pill" size="sm" @click="removePurpose(item,index)">
                                                <font-awesome-icon icon="times"/>
                                            </CButton>
                                            
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol col="12" class="text-right">
                                <CButton 
                                    :disabled="!isValid || purpose_list.length == 0"
                                    title="There is required field that is not filled out or no entry has been made to matrix."
                                    type="submit" 
                                    shape="pill" 
                                    color="primary"
                                    @click="saveMatrix()"
                                >
                                    <font-awesome-icon icon="save"/> Save
                                </CButton>
                            </CCol>
                        </CRow>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<script>
import { required,minLength } from "vuelidate/lib/validators"
export default {
    name: 'TemplateSignatoryMatrixCreate',
	data() {
		return {
            tsm: {
                name: null,
                description: null,
                // name: 'JO Routing Matrix',
                // description: 'Some jo routing matrix only.',
            },
            add: {
                purpose: null,
                sequence:null,
            },
            purpose_list: [
                // {"purpose":"Creator"},
                // {"purpose":"Verifier"},
                // {"purpose":"Approver"},
                // {"purpose":"Processor"}
            ],
            purpose_list_field: [
                {key: 'purpose'},
                {key: 'sequence'},
                {key: 'action'},
            ]
		}
	},
    validations: {
        tsm: {
            name: { required, minLength: minLength(3) },
            description: { required },
        },
    },
    computed: {
        isValid () { return !this.$v.tsm.$invalid },
        isDirty () { return this.$v.tsm.$anyDirty },
        duplicate_sequence: function () {
            return this.purpose_list.filter(x => x.sequence == this.add.sequence).length > 0 ? true : false;
        }
    },
    created() {
        this.$v.$touch()
    },
	methods: {
		addPurpose: function () {
            console.log(this.duplicate_sequence)
            if(this.duplicate_sequence) {
                for (let i = 0; i < this.purpose_list.length; i++) {
                    const element = this.purpose_list[i];
                    if(parseInt(element.sequence) >= parseInt(this.add.sequence)) {
                        element.sequence++;
                    }
                }
            }
            
            this.purpose_list.push({purpose: this.add.purpose, sequence: this.add.sequence})
            this.purpose_list.sort((a, b) => parseInt(a.sequence) - parseInt(b.sequence));
            this.add.purpose = null
            this.add.sequence = null
        },
        removePurpose: function (item, index) {
            this.purpose_list.splice(index, 1)
        },
        saveMatrix: function () {
            this.$swal({
                    icon: "warning",
                    title: "Are you sure?",
                    text: "You are trying to create new Matrix",
                    showCancelButton: true,
                    confirmButtonColor: "#DD6B55",
                    confirmButtonText: "Yes",
                    showLoaderOnConfirm: true,
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    preConfirm: () => { 
                        let data = {
                            name: this.tsm.name,
                            description: this.tsm.description,
                            purpose_list: this.purpose_list
                        }
                        this.$Progress.start()
                        return axios.post('/drs/template-signatory-matrix/store', data, {validateStatus: () => true})
                        .then(response => {
                            if ( response.status == 200 ) {
                                this.$swal({
                                    icon: "success",
                                    title: "Success!",
                                    html: `<b>${this.tsm.name}</b> has been added.`,
                                })

                                this.tsm.name = null
                                this.tsm.description = null
                                this.purpose_list = []
                                this.$v.$reset()
                                this.$v.$touch()
                                this.$Progress.finish()
                            }
                        })
                }
            })
        }
	}
}
</script>
